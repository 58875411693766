@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

@mixin font-face($weight, $filename) {
    $static-assets-path: '/static/op/opux-static/v3' !default;
    $font-dir: '#{$static-assets-path}/fonts';
    @font-face {
        font-family: $opds-font-platform-family;
        font-style: normal;
        font-weight: $weight;
        src: unquote(
            "url('#{$font-dir}/#{$filename}.woff2') format('woff2'), " +
                "url('#{$font-dir}/#{$filename}.woff') format('woff'), " +
                "url('#{$font-dir}/#{$filename}.ttf') format('truetype')"
        );
    }
}

$weights: map-get-deep($opds, 'font', 'weight');

@each $key, $weight in $weights {
    $filename: map-get-deep($opds, 'font', 'platform', 'name', $key);
    @include font-face($weight, $filename);
}
