// Shared base between all OPUX versions, which defines the reset,
// the Chevin font face and the base for font sizing. If modifying,
// make sure to also modify the respective @op/opux-static styles.

@import 'reset';
@import 'font-face';

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    color: #323232;
    font-family: 'Chevin', sans-serif;
    font-weight: 300;
}
